/*CSS Reset*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  color: #fff;
  font : {
    size: 100%;
  }
  font: inherit;
  text-align: justify;
  vertical-align: baseline;
}

/*Global Styles*/
html, body {
  height: 100%;
  /*overflow: hidden;*/
  @include background('/assets/bg-home.png');
  word-break: break-word;
  font: {
    family: 'Lato', sans-serif;
    size: 18px;
    weight: 300;
  }
}

a {
  color: $logopedie;
  font: {
    weight: 400;
  }
  word-break: break-word;
  &:hover {
    color: $logopedie;
    text: {
      decoration: underline;
    }
  }
}

img {
  width: 100%;
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  color: #fff;
  font: {
    weight: 300;
  }
  &.dark {
    color: #222222;
  }
}
h1 {
  font: {
    size: 3.8em;
  }
}
h2 {
  font: {
    size: 1.4em;
    weight: 400;
  }
  color: #fff;
  text-transform: uppercase;
  margin: {
    bottom: 0.7em;
  }
  &:before {
    content: '\00bb';
    margin: {
      right: 10px;
    }
  }
}

h3 {
  font: {
    size: 1.5em;
  }
}
h5 { font: {
  size: 1.25em;
}
}
h6 {
  font: {
    size: 1.1em;
  }
}
p {
  color: #fff;
  text-align: justify;
  margin: {
    bottom: 10px;
  }
  line: {
    height: 1.8em;
  }
  a {
    color: #ffffff;
    text-decoration: underline;
  }
}
span.fa {
  margin-right: 15px;
}
.spacer-small {
  height: 25px;
}
.spacer-normal {
  height: 50px;
}
.spacer-big {
  height: 75px;
}
.spacer-huge {
  height: 150px;
}
.relative {
  position: relative;
}
.main-frame {
  padding: {
    top:27px;
    right: 40px;
    bottom: 27px;
    left: 40px;
  }
}
.top-frame {
  margin: {
    top: 100px;
  }
}
.blue-frame {
  background: {
    color: $logopedie;
  }
}
.grey-frame {
  background: {
    color: #eaeaea;
  }
}
.white-frame {
  background: {
    color: #fff;
  }
}
.grey-box {
  background: {
    color: #eaeaea;
  }
  padding: {
    top:27px;
    right: 40px;
    bottom: 27px;
    left: 40px;
  }
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 7px;
}
.grey-box * {
  color: rgb(0,103,128);
}
.red-button-container {
  background: $huisartsen;
  > a {
    position: relative;
    color: #fff;
    display: block;
    height: 100%;
  }
}
.button-overview {

}
.slider-button-container {
  @include linear-gradient-appointment($huisartsen, $logopedie);
  &:hover {
    background: {
      position: right bottom;
    }
  }
  > a {
    position: relative;
    color: #fff;
    display: block;
    height: 100%;
  }
}