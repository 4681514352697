/* backgrounds */
.white-bg {
  background-color: #fafafa;
}
.info-bg {
  background-color: $info;
  /*opacity: 0.75;*/
}
.activiteiten-bg {
  background: {
    color: $activiteiten;
  }
}
.team-bg {
  background-color: $team;
}
.huisartsen-bg {
  background-color: $huisartsen;
}
.logopedie-bg {
  background-color: $logopedie;
}
.psychologen-bg {
  background-color: $psychologen;
}
.voeding-bg {
  background-color: $voeding;
}
.transparent {
  position: relative;
  z-index: 2;
  opacity: 0.9;
  filter: alpha(opacity=90);/*for ie8*/
}
.outer-wrapper {
  min-height: 100%;
  height: auto !important;
  margin: {
    top: 0;
    right: auto;
    bottom: -90px;
    left: auto;
  }
}

img.framed {
  background: {
    color: rgba(255, 255, 255, 0.5);
  }
  padding: 20px;
}


/* header */
header {
  padding-top: 45px;
  margin-bottom: 100px;
  &#home-header {
    img.logo {
      width: 500px;
      max-width: 80%;
    }
    .logo-caption {
      font: {
        family: 'Slabo 27px', serif;
        size: 1.3em;
      }
      letter-spacing: 0.09em;
      color: #000000;
      margin: {
        top: 8px;
      }
    }
  }
  &#page-header {
    h1 {
      font: {
        family: 'Slabo 27px', serif;
        size: 4em;
      }
      display: block;
      width: auto;
      float: left;
      > span.up {
        text-transform: uppercase;
      }
      margin: {
        top: -8px;
      }
    }
    .page-navi {
      float: left;
      margin: {
        top: 20px;
        left: -110px;
      }
      a {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin: {
          right: 10px;
        }
        &.home-page {
          @include home-page-navi('/assets/icons_png/home.png', '/assets/icons_svg/home.svg')
        }
        &.prev-page {
          @include previous-page-navi('/assets/icons_png/arrow-left-light.png', '/assets/icons_svg/arrow-left-light.svg')
        }
      }
    }
  }
}

.quotation {
  font: {
    family: 'Open Sans', sans-serif;
  }
  p {
    font: {
      style: italic;
    }
  }
  .quotation-mark {
    display: block;
    float:left;
    margin: {
      top: 12px;
      left: -55px;
    }
    width: 22px;
    height: 15px;
    background: url(/assets/quotes.png) no-repeat;
  }
  .quotation-author {
    display: block;
    float: right;
    font: {
      size: 0.85em;
    }
    padding: {
      top: 7px;
    }
    color: #FFF;
    border: {
      top: 1px solid #FFF;
    }
    opacity: 0.75;
    filter: alpha(opacity=75);
    margin: {
      top: 15px;
    }
  }
}

/* social bar */
ul.social-bar {
  list-style: none;
  float: right;
  margin: 5px 0 0 0;
  position: relative;
  z-index: 2;
  &.social-bar-white {
    li {
      a {
        opacity: 0.2;
        filter: alpha(opacity=20);/*for ie8*/
      }
    }
  }
  &.social-bar-black {
    li {
      a {
        opacity: 0.12;
        filter: alpha(opacity=12);/*for ie8*/
      }
    }
  }
  li {
    display: inline-block;
    margin: {
      left: 10px;
    }
    a {
      display: block;
      width: 32px;
      height: 32px;
      -webkit-transition: opacity 200ms ease-out;
      -moz-transition: opacity 200ms ease-out;
      -o-transition: opacity 200ms ease-out;
      transition: opacity 200ms ease-out;
      &.facebook-black {
        background: url(/assets/icons_png/facebook-black.png) no-repeat;/*fallback fo IE8*/
        background-image: url(/assets/icons_svg/facebook-black.svg), none;
      }
      &.facebook-white {
        background: url(/assets/icons_png/facebook-white.png) no-repeat;/*fallback fo IE8*/
        background-image: url(/assets/icons_svg/facebook-white.svg), none;
      }
      &.twitter-black {
        background: url(/assets/icons_png/twitter-black.png) no-repeat;/*fallback fo IE8*/
        background-image: url(/assets/icons_svg/twitter-black.svg), none;
      }
      &.twitter-white {
        background: url(/assets/icons_png/twitter-white.png) no-repeat;/*fallback fo IE8*/
        background-image: url(/assets/icons_svg/twitter-white.svg), none;
      }
      &.googleplus-black {
        background: url(/assets/icons_png/google-plus-black.png) no-repeat;/*fallback fo IE8*/
        background-image: url(/assets/icons_svg/google-plus-black.svg), none;
      }
      &.googleplus-white {
        background: url(/assets/icons_png/google-plus-white.png) no-repeat;/*fallback fo IE8*/
        background-image: url(/assets/icons_svg/google-plus-white.svg), none;
      }
      &:hover {
        opacity: 1;
        filter: alpha(opacity=100);/*for ie8*/
      }
    }
  }
}

/* appointment-bar */
.appointment-bar {
  .glyph {
    margin: 0 auto;
    border: 3px solid rgb(25, 101, 127);
    width: 125px;
    height: 125px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    i.front {
      font-size: 3.5em;
      text-align: center;
      color: rgb(25, 101, 127);
      display: block;
      position: relative;
      top: 30px;
    }
  }
  .coordinates {
    margin-top: 30px;
    font-size: 1.2em;
    text-transform: uppercase;
    text-align: center;
    color: rgb(25, 101, 127);
  }
}

/* documenten */
#documents {
  h4 {
    font: {
      family: 'Slabo 27px', serif;
      size: 1.2em;
    }
  }
  .document-wrapper {
    background: {
      color: rgba(255, 255, 255, 0.5);
    }
    padding: {
      top: 20px;
      right: 20px;
      bottom: 20px;
      left: 20px;
    }
    ul {
      margin: {
        left: 30px;
      }
      list: {
        style: {
          type: none;
        }
      }
      li {
        line: {
          height: 1.7em;
        }
      }
    }
  }
}


/* activiteiten */
#activities, #activity {
  .wrapper {
    background-color: $transparant;
    padding: 20px;
    margin-bottom: 40px;
    .info {
      margin-top: 15px;
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      border: 1px solid;
      .teachter > .label, .amount > .label, .price > .label {
        display: inline-block;
        width: 150px;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 5px;
      }
      .label::after {
        margin-left: 10px;
        content: ':';
      }
      label {
        position: relative;
        top: -24px;
        background-color: white;
        padding: 5px 20px;
        color: goldenrod;
      }
    }
    .description h3 {
      font-size: 1.1em;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: black;
    }
  }
  .title {
    padding: 20px 20px;
    text-align: center;
    background-color: goldenrod;
    font-family: 'Slabo 27px';
    font-size: 1.5em;
    margin-bottom: 0px;
  }
}

#activity {
  h4 {
    display: block;
    padding: 20px;
    background: {
      color: #fff;
    }
    font: {
      size: 0.9em;
    }
    text: {
      transform: uppercase;
    }
    color: $huisartsen;
  }
}

/* events */
#events {
  h4 {
    font-family: 'Slabo 27px', serif;
    font-size: 1.5em;
    margin: 20px 0;
    &:after {
      display: block;
      content: '';
      border-bottom: 3px solid goldenrod;
      margin-top: 20px;
    }
    h4 > span.fa {
      margin-right: 10px;
    }
  }
  .wrapper {
    margin-bottom: 20px;
    &:after {
      display: block;
      content: '';
      border-bottom: 1px solid #ffffff;
      padding: 10px 0;
      width: 90%;
      margin: 0 auto;
    }
  }
  .title {
    line-height: 1.4em;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  .read-more {
    text-align: right;
    margin-top: 10px;
    font-size: 0.8em;
    > a, > a:link, > a:visited {
    }
    > a:hover, > a:active {
      color: goldenrod;
    }
  }
  .month {
    background-color: goldenrod;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 0;
    border-radius: 4px 4px 0 0;
  }
  .datetime-wrapper {
    background-color: rgba(255,255,255,0.5);
    padding-bottom: 5px;
    border-radius: 4px;
    .day {
      font-size: 1.3em;
      text-align: center;
    }
    .dayofweek {
      text-transform: uppercase;
      font-size: 0.7em;
      font-weight: 400;
      text-align: center;
    }
  }
}
#event-detail {
  .wrapper {
    &:after {
      border-bottom: none;
    }
    .month {
      background-color: goldenrod;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 0;
      border-radius: 4px 4px 0 0;
    }
    .datetime-wrapper {
      background-color: rgba(255,255,255,0.5);
      padding-bottom: 5px;
      border-radius: 4px;
      .day {
        font-size: 1.3em;
        text-align: center;
      }
      .dayofweek {
        text-transform: uppercase;
        font-size: 0.7em;
        font-weight: 400;
        text-align: center;
      }
    }
    .header-box {
      padding: 23px 20px;
      font-family: 'Slabo 27px';
      font-size: 1.5em;
      text-transform: uppercase;
      margin-bottom: 0px;
    }
    .title {
      background-color: goldenrod;
    }
    .button-overview {
      background-color: $huisartsen;
      text-align: center;
    }
  }
}

/* team */
.team-member {
  font: {
    family: 'Open sans', sans-serif;
  }
  height: auto;
  min-height: 100%;
  padding: 20px;
  z-index: 0;
  > .wrapper {
    height: 100%;
    min-height: 600px;
    padding: {
      top: 20px;
      right: 20px;
      bottom: 20px;
      left: 20px;
    }
    background: {
      color: rgba(255,255,255,0.2);
    }
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    &:hover {
      background-color: rgba(255,255,255,0.5);
      > h4 {
        > span.fa {
          color: $huisartsen;
        }
      }
    }
    > img {
      height: auto;
    }
    > h4 {
      font-size: 1.3em;
      margin: {
        top: 15px;
        bottom: 15px;
      }
      > span.fa {
        color: #ffffff;
        margin: {
          right: 10px;
        }
        -webkit-transition: all 400ms ease-in-out;
        -moz-transition: all 400ms ease-in-out;
        -o-transition: all 400ms ease-in-out;
        transition: all 400ms ease-in-out;
      }
    }
    > .biography {
      color: $huisartsen;
      font: {
        size: 0.8em;
      }
      //opacity: 0.75;
      //filter: alpha(opacity=50);/*for ie8*/
    }
  }
}

/* nieuws */
#news, #news-detail {
  .news-wrapper {
    margin: {
      bottom: 50px;
    }
  }
  .title {
    font: {
      family: 'Slabo 27px', serif;
      size: 1.5em;
    }
    background: {
      color: goldenrod;
    };
    margin: {
      bottom: 20px;
    }
    padding: {
      top: 5px;
      right: 10px;
      bottom: 5px;
      left: 10px;
    }
  }
  .date {
    font: {
      size: 0.8em;
    }
    margin: {
      top: 0;
      bottom: 20px;
    }
  }
  .intro {
    p {
      font: {
        size: 0.9em;
      }
      line: {
        height: 1.5em;
      }
    }
  }
  h4 {
    &:after {
      display: block;
      content: '';
      border: {
        bottom: 3px solid goldenrod;
      }
      margin: {
        top: 20px;
      }
    }
  }
  .read-more {
    font: {
      size: 0.8em;
      style: italic;
    }
    margin: {
      top: 40px;
      right: 0;
      bottom: 20px;
      left: 0;
    }
    > a, a:visited {
      color: #ffffff;
    }
    > a:hover, a:active {
      color: goldenrod;
    }
  }
}

#news-detail {
  .read-more {
    text: {
      align: left;
    }
    margin: {
      top: 0;
    }
    > a, a:visited {
      padding: {
        top: 20px;
        right: 0;
        bottom: 20px;
        left: 0;
      }
      border: {
        bottom: 1px solid goldenrod;
      }
    }
  }
}