/* home nav */
nav#home-nav, nav#mobile-nav {
  a.navi {
    display: block;
    width: 100%;
    float: left;
    color: #fff;
    text-decoration: none;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: background-color 250ms ease-out;
    -moz-transition: background-color 250ms ease-out;
    -o-transition: background-color 250ms ease-out;
    transition: background-color 250ms ease-out;
    /* sizes */
    &.small-navi {
      height: 140px;
    }
    &.middle-navi {
      height: 140px;
      font: {
        size: 1.5em;
        weight: 300;
      }
    }
    &.high-navi {
      height: 310px;
    }
    &.big-navi {
      height: 310px;
      font: {
        size: 1.875em;
        weight: 300;
      }
    }
    /* types */
    &.huisartsen {
      background: {
        color: $huisartsen;
      }
    }
    &.logopedie {
      background: {
        color: $logopedie;
      }
      margin: {
        right: 10.06711409395973%;
      }
    }
    &.voeding {
      background: {
        color: $voeding;
      }
    }
    &.psychologen {
      background: {
        color: $psychologen;
      }
      margin: {
        right: 10.06711409395973%;
        top: 30px;
      }
    }
    &.info {
      background: {
        color: $info;
      };
    }
    &.info {
      background: {
        color: darkslategrey;
      }
      margin: {
        right: 10.06711409395973%;bottom: 30px;
      }
    }
    &.team {
      background: {
        color: $team;
      }
    }
    &.activiteiten {
      background: {
        color: $activiteiten;
      }
    }
    &.contact {
      background: {
        color: $contact;
      }
      margin: {
        top: 30px;
      }
    }
    &:hover {
      background: {
        color: $home-nav-hover
      };
      text-decoration: none;
    }
  }
}

/* page nav */
nav#page-nav {
  height: 100px;
  margin: {
    bottom: 0px;
  }
  -webkit-box-shadow: 0px 3px 3px black;
  -moz-box-shadow: 0px 3px 3px black;
  box-shadow: 0px 3px 3px black;
  z-index: 99999;
  .navbar-header {
    .navbar-logo img {
      max-height: 60px;
      width: inherit;
      padding: {
        top: 10px;
        right: 0;
        bottom: 10px;
        left: 0;
      }
      margin: {
        top: 20px;
        right: 30px;
      }
    }
  }
  .navbar-nav {
    height: 100px;
    > li {
      /* menu-buttons */
      > a {
        font: {
          weight: inherit;
        }
        padding: {
          top: 40px;
          bottom: 40px;
        }
        .caret {
          color: #777777;
        }
      }
      /* maak een afspraak-button */
      &.btn-appointment {
        height: 100%;
        @include linear-gradient-appointment($huisartsen, $logopedie);
        &:hover {
          background: {
            position: right bottom;
          }
        }
        > a {
          color: #fff;
          display: block;
          width: 220px;
          height: 100%;
          &:hover {
            position: relative;
          }
          &:after {
            content: url(/assets/calendar-icon.svg);
            display: block;
            position: absolute;
            top: 25px;
            left: 50px;
            -webkit-transition: opacity 1s ease-in-out;
            -moz-transition: opacity 1s ease-in-out;
            -ms-transition: opacity 1s ease-in-out;
            -o-transition: opacity 1s ease-in-out;
            transition: opacity 1s ease-in-out;
          }
        }
      }
    }
    .dropdown-menu {
      min-width: 188px;
      > li > a {
        clear: both;
        font: {
          weight: inherit;
        }
        line: {
          height: 1.42857143;
        }
        font: {
          size: 1.1em;
        }
      }
    }

  }
}