/*Sticky Footer*/
.push,
footer {
  height:90px;
  background: {
    color: #000000;
  };
}
footer {
  padding: {
    top: 70px;
  }
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text: {
    align: center;
  }
  color: #fff;
}