/* big desktop screens */
@media (min-width: 1200px) {
  #home .container {
    width: 1020px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  #home .container {
    width: 960px;
  }
  nav#home-nav {
    a.navi.small-navi {
      height: 130px;
    }
    a.navi.big-navi {
      height: 290px;
    }
  }
  nav#page-nav {
    height: auto;
    .navbar-nav {
      > li.btn-appointment {
        > a {
          width: 180px;
          &:after {
            left: 30px;
          }
        }
      }
    }
  }
  .nav>li>a {
    padding: 10px 10px;
  }

  /* team */
  #team {
    .team-member {
      > .wrapper {
        min-height: 525px;
        font: {
          size: 0.9em;
        }
      }
    }
  }
}

/* Small devices */
@media (max-width: 992px) and (min-width: 768px) {
  #home .container {
    width: 768px;
  }
  nav#home-nav {
    a.navi.small-navi {
      height: 98px;
    }
    a.navi.big-navi {
      height: 226px;
    }
  }
  nav#page-nav {
    height: auto;
  }
  .nav>li>a {
    padding: 10px 10px;
  }

  /* team */
  #team {
    .team-member {
      > .wrapper {
        min-height: 525px;
        font: {
          size: 0.9em;
        }
      }
    }
  }
}

/* Extra small devices (phones, less than 768px) */
@media (max-width: 768px) {
  body {
    background: {
      image: none;
      color: #ffffff;
    }
  }
  .spacer-small {
    height: 0;
    display: none;
  }
  .spacer-normal {
    height: 25px;
  }
  .spacer-big {
    height: 35px;
  }
  .spacer-huge {
    height: 75px;
  }
  .main-frame {
    padding: 0;
    margin: 0;
  }
  span.fa {
    margin: {
      right: 5px;
    };
  }

  /* header */
  header {
    &#home-header {
      img.logo {
        width: 100%;
        max-width: 100%;
      }
      .logo-caption {
        font: {
          size: 1.2em;
        }
        letter-spacing: 0.12em;
        color: #000000;
        margin: {
          top: 8px;
        }
      }
      ul.social-bar-black, ul.social-bar-white {
        position: absolute;
        top: 20px;
        left: 28%;
      }
      .logo-caption {
        font-size: 1.19em;
        letter-spacing: 0.35em;
        color: #000000;
        margin-top: 8px;
        text-align: center;
      }
    }
    &#page-header {
      padding: {
        top: 60px;
      }
      margin: {
        bottom: 40px;
      }
      h1 {
        display: block;
        width: 100%;
        text-align: center;
        font: {
          size: 2.1em;
        }
      }
      ul.social-bar {
        float: none;
        text-align: center;
        margin: {
          top: 60px;
        }
      }
    }
  }

  /* navigation */
  nav#mobile-nav {
    margin: {
      bottom: 0;
    }
    .navbar-header {
      background: #ffffff;
      color: #ffffff;
      padding: 0;
      .logo {
        padding: 10px 0;
        a {
          display: block;
          width: 100%;
          text: {
            align: center;
          }
          img {
            width: 60%;
          }
        }
      }
      .menu {
        background: {
          color: $logopedie;
        }
        border: {
          right: 1px solid #ffffff;
        }
      }
      .appointment {
        background: {
          color: $huisartsen;
        }
        border: {
          left: 1px solid #ffffff;
        }
      }
      .navbar-toggle {
        float: none;
        margin: {
          right: 0;
        }
      }
    }
    .navbar-brand {
      color: #ffffff;
      text-transform: uppercase;
    }
    .mobile-nav-wrapper {
      margin: {
        top: 20px;
      }
    }
    /* maak een afspraak-button */
    .btn-appointment {
      height: 100%;
      @include linear-gradient-appointment($huisartsen, $logopedie);
      &:hover {
        background: {
          position: right bottom;
        }
      }
      > a {
        color: #fff;
        display: block;
        width: 220px;
        height: 100%;
        &:hover {
          position: relative;
        }
        &:after {
          content: url(/assets/calendar-icon.svg);
          display: block;
          position: absolute;
          top: 25px;
          left: 50px;
          -webkit-transition: opacity 1s ease-in-out;
          -moz-transition: opacity 1s ease-in-out;
          -ms-transition: opacity 1s ease-in-out;
          -o-transition: opacity 1s ease-in-out;
          transition: opacity 1s ease-in-out;
        }
      }
    }
  }
  nav#home-nav, nav#mobile-nav {
    a.navi {
      display: block;
      width: 100%;
      float: left;
      color: #fff;
      text-decoration: none;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: background-color 250ms ease-out;
      -moz-transition: background-color 250ms ease-out;
      -o-transition: background-color 250ms ease-out;
      transition: background-color 250ms ease-out;
      /* sizes */
      &.logopedie, &.psychologen, &.info, &.contact {
        margin: {
          top: 0;
          right: 0;
          bottom: 10px;
          left: 0;
        }
      }
      &.small-navi {
        height: auto;
        margin: {
          bottom: 10px;
        }
      }
    }
  }

  /* contactformulier */
  .form-contact {
    padding: {
      top: 20px;
      right: 0;
      bottom: 20px;
      left: 0;
    }
  }
  .form-header {
    margin: {
      bottom: 20px;
    }
  }

  /* afsprakenknop */
  .appointment-bar {
    .glyph {
      border: 2px solid $logopedie;
      width: 100px;
      height: 100px;
      i.front {
        font-size: 3em;
        color: $logopedie;
        top: 25px;
      }
    }
    .coordinates {
      margin: {
        top: 10px;
        bottom: 25px;
      }
      font-size: 1.2em;
      text-transform: uppercase;
      text-align: center;
      color: rgb(25, 101, 127);
    }
  }

  /* nieuws */
  .news-wrapper {
    margin: {
      top: 30px;
    }
  }

  /* contact */
  input[type="submit"] {
    width: 100%;
  }

  /* documenten */
  #documents {
    .document-wrapper {
      padding: {
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
      }
      ul {
        margin: {
          left: 10px;
        }
        li {
          text: {
            align: left;
          }
        }
      }
    }
  }

  /* team */
  #team {
    .team-member {
      > .wrapper {
        min-height: inherit;
        font: {
          size: 1em;
        }
      }
    }
  }
}