/* contact form */
.form-contact {
  width: 100%;
  padding: {
    top: 40px;
    left: 80px;
    bottom: 0px;
    right: 100px;
  }
  font: {
    family: 'Lato', sans-serif;
  }
}

.form-group {
  margin: {
    top: 0;
    right: 0;
    bottom: 16px;
    left: 0;
  }
}

.form-header {
  color: $team;
  font: {
    family: 'Slabo 27px', serif;
    size: 1.4em;
  }
  margin: {
    bottom: 25px;
  }
  span {
    color: $logopedie;
    margin: {
      right: 10px;
    }
  }
}

.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  font-weight: 400;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  resize: none;
}

.input__field:focus {
  outline: none;
}

.input__label {
  display: inline-block;
  float: right;
  padding: 0 1em;
  width: 40%;
  font-size: 70.25%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  > span {
    color: $logopedie;
  }
}

.input__label-content {
  position: relative;
  display: block;
  padding: 1.6em 0;
  width: 100%;
}

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

.icon {
  color: #ddd;
  font-size: 150%;
}

input[type="submit"],
input[type="submit"]:link,
input[type="submit"]:focus,
input[type="submit"]:visited {
  outline: none;
  color: #ffffff;
  background: {
    color: $logopedie;
  }
  margin: {
    top: 15px;
  }
  padding: {
    top: 16px;
    right: 20px;
    bottom: 16px;
    left: 20px;
  }
  text-transform: uppercase;
}

input[type="submit"]:hover,
input[type="submit"]:active {
  color: $team;
}

.form-error {
  color: $huisartsen;
  font: {
    size: 0.8em;
    style: italic;
  }
}

.form-success {
  color: $voeding;
  padding: {
    top: 10px;
    right: 16px;
    bottom: 10px;
    left: 0px;
  }
  font: {
    size: 1.1em;
    weight: 500;
  }
  span.fa {
    color: $voeding;
    margin-right: 10px;
  }
}

/* Individual styles */

/* Ruri */
.input__field--ruri {
  width: 100%;
  background: transparent;
  padding: 0.5em 0;
  margin-bottom: 40px;
  color: $logopedie;
}

.input__label--ruri {
  width: 100%;
  position: absolute;
  text-align: left;
  font-size: 1em;
  padding: 10px 0 5px;
  pointer-events: none;
}

.input__label--ruri::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: $logopedie;
  left: 0;
  top: 100%;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition: -webkit-transform 0.3s, background-color 0.3s;
  transition: transform 0.3s, background-color 0.3s;
}

.input__label--ruri.input-error::after {
  background: $huisartsen;
}

textarea + .input__label--ruri::after {
  top: 140px;
}

.input__label-content--ruri {
  padding: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform 0.3s, color 0.3s;
  transition: transform 0.3s, color 0.3s;
}

textarea + label > span.input__label-content--ruri {
  -webkit-transform-origin: 0 310px;
  transform-origin: 0 310px;
  -webkit-transition: -webkit-transform 0.3s, color 0.3s;
  transition: transform 0.3s, color 0.3s;
}

.input__field--ruri:focus + .input__label--ruri::after,
.input--filled .input__label--ruri::after {
  background: $team;
  -webkit-transform: scale3d(1, 0.25, 1);
  transform: scale3d(1, 0.25, 1);
}

.input__field--ruri:focus + .input__label--ruri .input__label-content--ruri,
.input--filled .input__label--ruri .input__label-content--ruri {
  color: $team;
  -webkit-transform: translate3d(0, 2em, 0) scale3d(0.655, 0.655, 1);
  transform: translate3d(0, 2em, 0) scale3d(0.655, 0.655, 1);
}