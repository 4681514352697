/* news feed */
#home {
  .events-wrapper {
    .event-box {
      background: {
        color: rgba(255, 255, 255, 0.5)
      }
      padding: 10px;
      margin-bottom: 20px;
      .title {
        line-height: 1.4em;
        text-transform: uppercase;
        margin-bottom: 5px;
      }
      .read-more {
        text-align: right;
        margin-top: 10px;
        font-size: 0.8em;
        > a, > a:link, > a:visited {
        }
        > a:hover, > a:active {
          color: goldenrod;
        }
      }
      .month {
        background-color: goldenrod;
        text-align: center;
        text-transform: uppercase;
        padding: 5px 0;
        border-radius: 4px 4px 0 0;
      }
      .datetime-wrapper {
        background-color: rgba(255,255,255,0.5);
        padding-bottom: 5px;
        border-radius: 4px;
        .day {
          font-size: 1.3em;
          text-align: center;
        }
        .dayofweek {
          text-transform: uppercase;
          font-size: 0.7em;
          font-weight: 400;
          text-align: center;
        }
      }
      ul {
        list-style: none;
        margin: {
          left: 15px;
        }
        > li {
          margin: {
            bottom: 7px;
          }
          &:before {
            content: '\203A';
            margin: {
              right: 10px;
            }
          }
        }
      }
    }
  }
  .news-wrapper {
    p {
      margin-bottom: 10px;
    }
    .news-feed-title {
      display: block;
      padding: {
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 10px;
      }
      background: {
        color: $activiteiten;
      }
      font: {
        size: 0.9em;
      }
      border: {
        radius: 4px;
      }
    }
    .news-feed-intro {
      font: {
        size: 0.8em;
      }
      margin: {
        top: 10px;
      }
    }
    .news-feed-author {
      float: right;
      font: {
        family: "Open sans", sans-serif;
        size: 0.75em;
      }
    }
    .news-feed-author a {
      display: inline-block;
      background: url(/assets/icons_png/twit.png) no-repeat;/*fallback fo IE8*/
      background-image: url(/assets/icons_svg/twit.svg), none;
      background-repeat: no-repeat;
      background-position: left center;
      height: 14px;
      text-indent: 21px;
      > span {
        display: inline-block;
        color: #aaaaaa;
      }
    }
  }
  /* activities and news */
  .readmore {
    font: {
      size: 0.9em;
    }
    line-height: 1.8em;
    span.fa {
      margin: {
        right: 5px;
      }
    }
    a {
      color: #ffffff;
      text-decoration: underline;
      font: {
        weight: 500;
      }
      &:link, &:visited {
        color: #ffffff;
      }
      &:hover, &:active {
        color: $voeding;
      }
    }
  }
  .btn-appointment {
    height: 100px;
    @include linear-gradient-appointment($huisartsen, $logopedie);
    &:hover {
      background: {
        position: right bottom;
      }
    }
    > a {
      position: relative;
      color: #fff;
      display: block;
      width: 220px;
      height: 100%;
      &:after {
        content: url(/assets/calendar-icon.svg);
        display: block;
        position: absolute;
        top: 25px;
        left: 50px;
        -webkit-transition: opacity 1s ease-in-out;
        -moz-transition: opacity 1s ease-in-out;
        -ms-transition: opacity 1s ease-in-out;
        -o-transition: opacity 1s ease-in-out;
        transition: opacity 1s ease-in-out;
      }
    }
  }

  /* google maps */
  .map-responsive {
    overflow:hidden;
    position:relative;
    height: 650px;
  }
  .map-responsive iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
  .scrolloff iframe {
    pointer-events: none ;
  }
}
