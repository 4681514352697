/* import fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,300&subset=latin,cyrillic');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
@import url('https://fonts.googleapis.com/css?family=Lato:100,200,300,400|Slabo+27px');

/* variables */
$huisartsen: rgb(211,79,51);
$psychologen: rgb(227,139,36);
$logopedie: rgb(0,103,128);
$voeding: rgb(83,169,131);
$team: goldenrod;
$info: darkslategrey;
$activiteiten: rgb(85, 157, 159);
$contact: darkslategrey;
$home-nav-hover: #323232;
$placeholder: lightgrey;
$transparant: rgba(255, 255, 255, 0.5);

/* mixins */
/// achtergrond body
@mixin background($img) {
  background: url($img) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/// go to home-button
@mixin home-page-navi($img_ie, $img) {
  background: url($img_ie) no-repeat;/*fallback fo IE8*/
  background-image: url($img), none;
  background-repeat: no-repeat;
}

/// go to previous button
@mixin previous-page-navi($img_ie, $img) {
  background: url($img_ie) no-repeat;/*fallback fo IE8*/
  background-image: url($img), none;
  background-repeat: no-repeat;
}

/// linear-gradient voor afspraak-button
@mixin linear-gradient-appointment($red, $blue) {
  background-color: $huisartsen;
  background: linear-gradient(to right, $red 50%, $blue 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@mixin input-fade-effect() {

}